import moment from "moment/moment";
import { blueGrey, green, orange, red, yellow } from "@mui/material/colors";
import {
  ACEPT,
  CANCEL,
  COMPLETED,
  IN_PROCESS,
  IN_PROCESS_CHECK,
  NEED_MODIFY,
  ESTAND_CHECK,
} from "./constants";
import { app } from "./server/firebase";
import {
  getAuth,
  isSignInWithEmailLink,
  sendSignInLinkToEmail,
  signInWithEmailLink,
} from "firebase/auth";

import { API } from "./constants";
import { navigate } from "gatsby";

export const sendEmail = async (data) => {
  try {
    const resp = await API.post("/send-email.php", {
      ...data,
    });
    return resp.status === 200;
  } catch (error) {
    console.log(error);
  }
};

export function validateEmail(email) {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLocaleLowerCase());
}

export const getStatusColor = (status) => {
  switch (status) {
    case IN_PROCESS:
      return blueGrey[500];
    case IN_PROCESS_CHECK:
      return blueGrey[500];
    case NEED_MODIFY:
      return orange[500];
    case ESTAND_CHECK:
      return green[400];
    case COMPLETED:
      return green[500];
    case ACEPT:
      return yellow[600];
    case CANCEL:
      return red[500];
  }
};

export const getLastStatus = (request) => {
  if (request?.feedbacks) {
    const status = request.feedbacks.sort((a, b) => {
      const dateA = moment.utc(a.createAt * 1000);
      const dateB = moment.utc(b.createAt * 1000);
      if (dateA > dateB) return -1;
      if (dateA < dateB) return 1;
      return 0;
    });
    return status[0].status;
  } else {
    return request.status;
  }
};

export const getLastFeedback = (request) => {
  if (request?.feedbacks) {
    const status = request.feedbacks.sort((a, b) => {
      const dateA = moment.utc(a.createAt * 1000);
      const dateB = moment.utc(b.createAt * 1000);
      if (dateA > dateB) return -1;
      if (dateA < dateB) return 1;
      return 0;
    });
    return status[0].observations;
  } else {
    return request.observations;
  }
};

export const getLastAceptedStatus = (feedbacks) => {
  const status = feedbacks
    .filter((feed) => feed.status === ESTAND_CHECK)
    .sort((a, b) => {
      const dateA = moment.utc(a.createAt * 1000);
      const dateB = moment.utc(b.createAt * 1000);
      if (dateA > dateB) return -1;
      if (dateA < dateB) return 1;
      return 0;
    });
  return status[0];
};

export const getQRValue = (data) => {
  const baseUrl = "https://festivaldelchocolate.mx/ficha-pago";
  let params = "";

  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      params += key + "=" + data[key] + "&";
    }
  }

  return baseUrl + "?" + params;
};

export const getTimes = (
  currentDate,
  startTime,
  endTime,
  excludedTimes = []
) => {
  const times = [];
  const interval = 30;

  // Crea objetos Moment para la fecha de inicio y la fecha de finalización
  const start = moment(currentDate).set("hour", startTime).set("minute", 0);
  const end = moment(currentDate).set("hour", endTime).set("minute", 0);

  // Genera los horarios de 30 minutos dentro del rango
  let current = start.clone();
  while (current.isBefore(end)) {
    const startTime = current.clone();
    const endTime = current.clone().add(interval, "minutes");

    const excluded = excludedTimes.some((item) => {
      const startExcluded = moment(item.start.toDate());
      const endExcluded = moment(item.end.toDate());
      return startTime.isSame(startExcluded) && endTime.isSame(endExcluded);
    });

    if (!excluded) {
      times.push({ start: startTime, end: endTime });
    }

    current.add(interval, "minutes");
  }

  return times;
};

export const atraparInicioSesion = (url) => {
  const auth = getAuth(app);
  if (isSignInWithEmailLink(auth, url)) {
    const correoRegistro =
      typeof window !== "undefined" && window.localStorage.getItem("correo");

    signInWithEmailLink(auth, correoRegistro, url)
      .then((result) => {
        console.log("éxito", result);
        navigate("/negocios");
      })
      .catch((error) => {
        console.log("error", error);
      });
  } else {
    console.log("no es un enlace de inicio de sesión");
  }
};

export const enviarEnlaceLogin = (correo) => {
  const auth = getAuth(app);
  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // url: "https://festivaldelchocolate.mx/negocios/loginUrl",
    url: "http://localhost:8000/negocios/loginUrl",

    handleCodeInApp: true,
    // iOS: {
    //   bundleId: "com.example.ios"
    // },
    // android: {
    //   packageName: "com.example.android",
    //   installApp: true,
    //   minimumVersion: "12"
    // },
  };

  sendSignInLinkToEmail(auth, correo, actionCodeSettings)
    .then((response) => {
      console.log("éxito", response);
    })
    .catch((error) => {
      console.log("error", error);
    });
};
